<template>
  <div class="relative bg-white rounded z-10" v-on-click-outside="closeMenu">
    <icon-button
      role="download-menu-button"
      @click.stop="toggleMenu"
      view-box="0 -5 17 24"
      icon="download"
    />
    <div
      v-show="showMenu"
      class="absolute mt-1 z-10 right-0 max-h-60 overflow-y-auto shadow-lg rounded-md bg-gray-100 text-sm text-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
    >
      <div v-if="fileTypes.length > 1" class="flex px-5 py-5">
        <lf-button
          class="px-3 w-32 h-9 text-gray-500 focus:outline-none uppercase"
          v-for="(fileType, i) in fileTypes"
          :key="i"
          :class="{
            'bg-primary sm:text-white': selectedFileType === fileType,
            'bg-white': selectedFileType !== fileType,
            'ml-5': i > 0
          }"
          @click="selectFileType(fileType)"
        >
          {{ fileType }}
        </lf-button>
      </div>
      <div class="py-5 bg-white w-full">
        <primary-button
          class="bg-primary w-10/12 h-10 mx-auto focus:outline-none"
          @click.stop="emitDownload"
        >
          {{ $t("COMMON.DOWNLOAD") }}
          <span v-if="fileTypes.length === 1" class="uppercase">
            &nbsp;{{ selectedFileType }}
          </span>
        </primary-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import IconButton from "@/components/ui/buttons/IconButton.vue";

const props = defineProps({
  fileTypes: {
    type: Array,
    required: true
  }
});
const emit = defineEmits(["download"]);

const showMenu = ref(false);
const selectedFileType = ref(props.fileTypes[0]);

const selectFileType = (format) => {
  selectedFileType.value = format;
};

const toggleMenu = () => {
  if (!showMenu.value) {
    document.body.click();
  }
  showMenu.value = !showMenu.value;
};

const closeMenu = () => {
  showMenu.value = false;
};

const emitDownload = () => {
  closeMenu();
  emit("download", selectedFileType.value);
};
</script>
